import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Services.css";

// import service1 from "../../../Assets/Services/2d.jpg";
// import service2 from "../../../Assets/Services/3d.jpg";
// import service3 from "../../../Assets/Services/2.png";
// import service4 from "../../../Assets/Services/Brand_AUDIT.png";
// import service5 from "../../../Assets/Services/social_media.png";
// import service6 from "../../../Assets/Services/ui_ux.png";
// import service7 from "../../../Assets/Services/ENTERPRISE.png";
// import service8 from "../../../Assets/Services/website.png";


// import client1 from "../../../Assets/Services/legelty.png";
// import client2 from "../../../Assets/Services/bgx.png"; 
// import client3 from "../../../Assets/Services/bossa.png";
// import client4 from "../../../Assets/Services/kareo.png";
// import client5 from "../../../Assets/Services/retune.png";
// import client6 from "../../../Assets/Services/Australian-Governement.png";

import client1 from "../../../Assets/Clients/client1.webp"
import client2 from "../../../Assets/Clients/client2.webp"
import client3 from "../../../Assets/Clients/client3.webp"
import client4 from "../../../Assets/Clients/client4.webp"
import client5 from "../../../Assets/Clients/client5.webp"
import client6 from "../../../Assets/Clients/client6.webp"
import client7 from "../../../Assets/Clients/client7.webp"
import client8 from "../../../Assets/Clients/client8.webp"
import client9 from "../../../Assets/Clients/client9.webp"
import client10 from "../../../Assets/Clients/client10.webp"
import client11 from "../../../Assets/Clients/client11.webp"
import client12 from "../../../Assets/Clients/client12.webp"
import client13 from "../../../Assets/Clients/client13.webp"
import client14 from "../../../Assets/Clients/client14.webp"
import client15 from "../../../Assets/Clients/client15.webp"
// import client16 from "../../../Assets/Clients/client16.png"


    const serviceTypes = [
        {
            id: 1,
            title: " 3D Explainer Videos",
            description: "Of course, this is where 3D animation production shines brightest. We create highly detailed, visually rich videos that turn abstract ideas into fully fleshed-out experiences. Whether it’s a product demo or a process breakdown, we’ll make it look stunning with our 3D animation video production.",
        },
        {
            id: 2,
            title: "Storytelling Testimonial Videos",
            description: "Customer stories get a modern twist with 3D animation services. Picture realistic characters, beautifully rendered settings, and visual effects that make their journey come alive. It’s storytelling at its most vibrant, perfect for showcasing how your brand impacts lives.",
        },
        {
            id: 3,
            title: "Educational Videos",
            description: "Learning is way more engaging when it feels like a journey. With 3D animation services, we craft visuals that bring your lessons to life, whether it’s a walk-through of complex systems or a dynamic animated guide.",
        },
        {
            id: 4,
            title: "Trade Show Videos",
            description: "3D product animation services are showstoppers. From spinning product models to immersive brand stories, these videos grab attention on the big screens and keep your booth buzzing. Whether it's a 3D animation studio in Dubai or our 3D production house, we ensure your content stands out.",
        },
        {
            id: 5,
            title: "Live Action Videos",
            description: "Blend reality with imagination. By adding 3D animation to live footage, we create hybrid videos that feel innovative and exciting-perfect for grabbing your audience’s attention. Working with the best 3D animation studio, we’ll take your live-action footage to new heights.",
        },
        {
            id: 6,
            title: "Social Video Ads",
            description: "In a feed full of flat visuals, 3D animation leaps off the screen. Bold, vibrant, and endlessly scroll-stopping, these videos make your brand impossible to ignore. 3D video agency expertise makes all the difference in creating that extra layer of engagement.",
        },
        {
            id: 7,
            title: "Video Case Studies",
            description: "Data gets a glow-up with 3D product animation. We’ll build sleek visualizations and scenarios that let your success stories shine, making even the most technical details feel accessible and impressive. Our 3D animation house makes sure your results are presented in the best way possible.",
        },
        // {
        //     id: 8,
        //     title: "Video Courses",
        //     description: "Want to keep learners hooked? 3D animation interior design elements and dynamic visuals add depth and movement to your lessons, making educational content as visually rich as it is informative. At our 3D animation design studio, we know how to keep your audience engaged.",
        // },
        {
            id: 9,
            title: "Brand Videos",
            description: "Your brand deserves to stand out, and 3D animation does just that. From lifelike environments to unique visual effects, we’ll craft a story that’s as bold and unique as your brand. As a 3D animation company with top-notch design, we ensure your brand video makes a lasting impact.",
        },
        {
            id: 10,
            title: "Video Retainer",
            description: "Need fresh content on the regular? With our 3D animation services company, we’ll keep your visuals innovative, dynamic, and always ahead of the curve. Our 3D video company will ensure you always have fresh content that resonates with your audience.",
        } 
    ];

    const clients = [ client1, client2, client3, client4, client5, client6, client7, client8, client9, client10, client11, client12, client13, client14, client15 ];

    // const service = {
    //     id: 1,
    //         name: "2D Animation",
    //         activeSection: "2d-animation",
    //         description: "Our 2D animation service is designed to elevate brand storytelling with a unique, hand-crafted touch. From character animations to kinetic typography, we create fluid, colorful visuals that engage and inform. Every element is meticulously animated to capture attention and convey your message with creativity and clarity, making 2D animation ideal for explainers, educational videos, and promotional content.",
    //         serviceTypes: [ "Explainer Videos", "Infographic Videos", "Training Videos", "Promotional Videos", "Social Media Videos", "Educational Video", "Storytelling Video", "Case Study Video", "Traditional Animation", "Digital 2D Animation", "Frame-by-Frame Animation", "Cut-Out Animation", "Motion Graphics Animation", "Whiteboard Animation", "Stop-Motion Inspired", "Cel Animation", "Vector Animation", "Experimental Animation", "Web Animation" ],  
    //         clients: [client1, client2, client3, client4]
    // }
    

    // const allServices = [
    //     {
    //         id: 1,
    //         name: "2D Animation",
    //         activeSection: "2d-animation",
    //         img: service1,
    //         description: "Our 2D animation service is designed to elevate brand storytelling with a unique, hand-crafted touch. From character animations to kinetic typography, we create fluid, colorful visuals that engage and inform. Every element is meticulously animated to capture attention and convey your message with creativity and clarity, making 2D animation ideal for explainers, educational videos, and promotional content.",
    //         serviceTypes: [ "Traditional Animation", "Digital 2D Animation", "Frame-by-Frame Animation", "Cut-Out Animation", "Motion Graphics Animation", "Whiteboard Animation", "Stop-Motion Inspired", "Cel Animation", "Vector Animation", "Experimental Animation", "Web Animation" ],
    //             // "Explainer Videos", "Infographic Videos", "Training Videos", "Promotional Videos", "Social Media Videos", "Educational Video", "Storytelling Video", "Case Study Video"
    //         clients: [client1, client2, client3, client4]
    //     },
    //     {
    //         id: 2,
    //         name: "3D Animation",
    //         activeSection: "3d-animation",
    //         img: service2,
    //         description: "3D animation allows you to showcase products, ideas, and worlds in lifelike, three-dimensional detail. Perfect for product visualizations, interactive simulations, and cinematic experiences, our 3D animation work combines technical expertise with artistic skill. By blending realistic textures, lighting, and motion, we create immersive animations that leave a lasting impression and bring your vision into a tangible, dynamic format.",
    //         serviceTypes: [ "Character Animation", "Motion Graphics Animation", "Architectural Visualization", "Mechanical Animation", "Environmental Animation", "Simulation Animation", "Product Visualization Animation", "Medical Animation", "Stop-Motion Inspired", "VR Animation", "VFX", "Full-CGI Animation" ],
    //         clients: [client1, client2, client3, client4]
    //     },
    //     {
    //         id: 3,
    //         name: "Video Production",
    //         activeSection: "video-production",
    //         img: service3,
    //         description: "Video production transforms ideas into captivating visual stories, blending creativity and technical precision. Whether it’s for promotional campaigns, corporate presentations, or storytelling, our video production services bring your concepts to life. By combining high-quality visuals, expert editing, and compelling narratives, we deliver videos that engage audiences, evoke emotions, and leave a lasting impact. From pre-production planning to final cut, we craft videos tailored to meet your unique needs and vision.",
    //         serviceTypes: ["TV Production", "Film Production", "Animated Videos", "Event Coverage"],
    //         clients: [client1, client2, client3, client4]
    //     },
    //     {
    //         id: 4,
    //         name: "Branding",
    //         activeSection: "branding",
    //         img: service4,
    //         description: "Branding is the art of shaping perceptions and creating a unique identity that resonates with your audience. Our branding services go beyond logos; we craft cohesive visual identities, memorable messaging, and strategic positioning that define your brand's essence. By blending creativity with market insights, we help you build a strong, consistent presence that fosters trust, loyalty, and recognition. Whether you're starting fresh or reimagining your brand, we bring your vision to life with impactful design and strategy.",
    //         serviceTypes: ["Brand Audits", "Brand Positioning", "Brand Identity", "Brand Design", "Logo Design", "Packaging Design", "UI & UX Design", "Web Design"],
    //         clients: [client1, client2, client3, client4]
    //     },
    //     {
    //         id: 5,
    //         name: "Digital Marketing",
    //         activeSection: "digital-marketing",
    //         img: service5,
    //         description: "Digital marketing connects your brand with the right audience through targeted strategies and innovative solutions. From SEO and social media to email campaigns and PPC advertising, our digital marketing services are designed to drive engagement, boost visibility, and deliver measurable results. By combining data-driven insights with creative execution, we help your business grow in the ever-evolving digital landscape. Let us amplify your online presence and turn clicks into lasting customer relationships.",
    //         serviceTypes: ["SEO & SMO", "Social Media", "Google Adwords", "Email Marketing"],
    //         clients: [client1, client2, client3, client4]
    //     },
    //     {
    //         id: 6,
    //         name: "Website Development",
    //         activeSection: "web-development",
    //         img: service6,
    //         description: "Web development transforms your ideas into functional, user-friendly digital experiences. Our expertise lies in creating responsive, high-performance websites that not only look stunning but also deliver seamless functionality. From custom designs to e-commerce platforms, we build solutions tailored to your needs. By blending cutting-edge technology with intuitive design, we ensure your website engages users, drives conversions, and represents your brand in the best light.",
    //         serviceTypes: ["Custom Design", "E-Commerce Solutions", "Web Applications", "CMS Integration", "SEO Optimization", "Website Maintenance", "Shopify Development", "Enterprise Solutions"],
    //         clients: [client1, client2, client3, client4]
    //     },
    //     {
    //         id: 7,
    //         name: "Software Development",
    //         activeSection: "software-development",
    //         img: service7,
    //         description: "Software development turns your vision into powerful, tailored solutions that drive efficiency and innovation. Our team specializes in creating custom software, from intuitive applications to robust enterprise systems, designed to meet your unique business needs. By leveraging the latest technologies and best practices, we deliver scalable, secure, and user-friendly software that empowers your organization to achieve its goals and stay ahead in a dynamic digital landscape.",
    //         serviceTypes: ["Custom Design", "E-Commerce Solutions", "Web Applications", "CMS Integration", "SEO Optimization", "Website Maintenance", "Shopify Development", "Enterprise Solutions"],
    //         clients: [client1, client2, client3, client4]
    //     },
    //     {
    //         id: 8,
    //         name: "Application Development",
    //         activeSection: "application-development",
    //         img: service8,
    //         description: "Application development brings your ideas to life with innovative, user-centric solutions for mobile and desktop platforms. Whether it's a sleek mobile app, a feature-rich web application, or a custom business tool, we create applications that combine functionality, performance, and intuitive design. From concept to deployment, our expertise ensures your app meets your needs, engages users, and adapts seamlessly to evolving technologies.",
    //         serviceTypes: ["Custom Design", "E-Commerce Solutions", "Web Applications", "CMS Integration", "SEO Optimization", "Website Maintenance", "Shopify Development", "Enterprise Solutions"],
    //         clients: [client1, client2, client3, client4]
    //     }
    // ]


export default function Services({size}) {

    const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex((prevIndex) => (prevIndex + 1) % service.serviceTypes.length);
    //     }, 2000); // Change every 2 seconds
    //     return () => clearInterval(interval); // Cleanup on unmount
    // }, []);

    const textVariants = {
        initial: {
          x: -size*5,
          opacity: 0
        },
        animate: {
          x: 0,
          opacity: 1,
          transition: {
            duration: 1,
            staggerChildren: 0.1
          }
        },
        scrollButtonInitial: {
          opacity: 0
        },
        scrollButton: {
          opacity: 1,
          y: 10,
          transition: {
            duration: 2,
            repeat: Infinity
          }
        }
      } 

    const settings = {
        // customPaging: function(i) {
        //   return (
        //     <a>
        //       <img 
        //         src={clientImages[i]} 
        //         alt={`Client ${i + 1}`} 
        //         style={{ width: "20px", height: "20px", objectFit: "cover" }} 
        //     />
        //     </a>
        //   );
        // },
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => setCurrentIndex(next)
      };

      const settings1 = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 2
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false
            }
            }
        ]
      };
    return (
        <section id="services" className="services">
            <div className="overlay"></div>
            <div className="services-content">
                <motion.div className="top container1" variants = {textVariants} initial="initial" whileInView="animate">
                    {/* <motion.div className="header-container"> */}
                        <motion.h1 className="heading">3D Animation provides <div className="service-types">
                            <Slider {...settings}>
                                {serviceTypes.map((ele) => {
                                    return (
                                        <div className="types" key={ele.id}>
                                            <h1>{ele.title}</h1>
                                        </div>
                                    )
                                })}
                            </Slider> that visually convey engaging narratives.</div> 
                        </motion.h1>
                    {/* </motion.div> */}
                    <motion.p>{serviceTypes[currentIndex].description}</motion.p>
                    <motion.a href="#about-contact"><button className="btn">
                        Book A free Consultation
                    </button></motion.a>
                </motion.div>
                <div className="bottom-overlay"></div>
                <div className="slider-container">
                    <Slider {...settings1}>
                        {clients.map((ele, index) => {
                            return (
                                <div key={index} className="slider-image-container"> 
                                    <img src={ele} alt="" className="slider-image" />
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                {/* <motion.div 
                    variants={sliderVarient}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="bottom">
                    <img src={client1} alt="" />
                    <img src={client2} alt="" />
                    <img src={client3} alt="" />
                    <img src={client4} alt="" />
                    <img src={client5} alt="" />
                    <img src={client6} alt="" />
                </motion.div> */}
            </div>
        </section>
    );
}