import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './Components/Common/Navbar/Navbar';
import Navbar1 from "./Components/Common/PageNotFound/Navbar1";
import Footer from './Components/Common/Footer/Footer';
import Footer1 from "./Components/Common/PageNotFound/Footer1";
// import Thankyou from './Pages/Thankyou';
import PopupForm from './Components/Common/PopupForm/PopupForm';
import PageNotFound from './Components/Common/PageNotFound/PageNotFound';
// import ZohoForm from './Components/Common/PopupForm/ZohoForm';

// import AOS from "aos";
// import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    import('./Components/Common/Scripts/trackingscripts').then(({ initGoogleAnalytics, initClarity, initFacebookPixel }) => {
        initGoogleAnalytics();
        initClarity();
        initFacebookPixel();
    });
  }, []);

//   useEffect(() => {
//     // Load Google Analytics scripts
//     const gascript = document.createElement('script');
//     gascript.async = true;
//     gascript.src = "https://www.googletagmanager.com/gtag/js?id=G-5JRTNW173W";
//     document.head.appendChild(gascript);

//     gascript.onload = () => {
//         window.dataLayer = window.dataLayer || [];
//         function gtag() { window.dataLayer.push(arguments); }
//         gtag('js', new Date());

//         // Configure each Google Analytics tag ID
//         gtag('config', 'G-5JRTNW173W');
//     };

//     // Microsoft Clarity Script
//     const clarityScript = document.createElement('script');
//     clarityScript.type = "text/javascript";
//     clarityScript.innerHTML = `
//       (function(c,l,a,r,i,t,y){
//           c[a]=c[a]||function(){
//           (c[a].q=c[a].q||[]).push(arguments)
//           };
//           t=l.createElement(r);
//           t.async=1;
//           t.src="https://www.clarity.ms/tag/"+i;
//           y=l.getElementsByTagName(r)[0];
//           y.parentNode.insertBefore(t,y);
//       })(window, document, "clarity", "script", "p4zv7o0ryu");
//     `;
//     document.head.appendChild(clarityScript);

//     // Add the Meta Pixel script dynamically
//     const pixelScript = document.createElement("script");
//     pixelScript.innerHTML = `
//       !function(f,b,e,v,n,t,s)
//       {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//       n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//       if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//       n.queue=[];t=b.createElement(e);t.async=!0;
//       t.src=v;s=b.getElementsByTagName(e)[0];
//       s.parentNode.insertBefore(t,s)}(window, document,'script',
//       'https://connect.facebook.net/en_US/fbevents.js');
//       fbq('init', '572888992306616');
//       fbq('track', 'PageView');
//     `;
//     document.head.appendChild(pixelScript);

//     // Add the <noscript> tag for no-JavaScript fallback
//     const noscriptTag = document.createElement("noscript");
//     noscriptTag.innerHTML = `
//       <img height="1" width="1" style="display:none"
//       src="https://www.facebook.com/tr?id=572888992306616&ev=PageView&noscript=1"
//       />
//     `;
//     document.body.appendChild(noscriptTag);

//     // Cleanup function to remove scripts on unmount
//     return () => {
//         document.head.removeChild(gascript);
//         document.head.removeChild(clarityScript);
//         document.head.removeChild(pixelScript);
//         document.body.removeChild(noscriptTag);
//     };
// }, []); // Empty dependency array to load only when Home mounts

  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
  const [size, setSize] = useState(50); // Default size based on initial width
  const [duration, setDuration] = useState(.5)

  useEffect(() => {
      // Function to handle resize and set screen width and size accordingly
      const handleResize = () => {
          const width = window.innerWidth;
          // setScreenWidth(width);
          if (width < 500) {
            setSize(0);
            setDuration(.2)
          } else if (width < 700) {
            setSize(10);
            setDuration(.3)
          } else if (width < 1200) {
            setSize(20);
            setDuration(.4)
          } else {
            setSize(50); // Default size for widths above 1200
            setDuration(.5)
          }
      };
      
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
      
      // Initial check in case window width has already changed
      handleResize();
      
      // Clean up event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const location = useLocation();
  const [closePopup, setClosePopup] = useState(false);
  const [formAppear, setFormAppear] = useState(false);

  // Function to handle closing the popup and storing flag in localStorage
  const handleClose = () => {
    setClosePopup(true);
    localStorage.setItem('formAppear', 'true'); // Set flag so popup doesn't reappear
  };

  // Check localStorage on initial load
  useEffect(() => {
    const formAppearFlag = localStorage.getItem('formAppear');
    if (formAppearFlag === 'true') {
      setFormAppear(false); // Popup should not appear if previously closed
    } else {
      setFormAppear(true); // Show popup if it's the first visit
    }
  }, []);

  const [activeSection, setActiveSection] = useState('video-production');

  const handleSectionChange = (section) => {
    // if(section === "software-development"){
    // setActiveSection("web-development");
    // } else {
      setActiveSection(section);
    // }
  }

  const [isHalfway, setIsHalfway] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY; // Current vertical scroll position
  //     const viewportHeight = window.innerHeight; // Height of the visible part of the page
  //     const totalHeight = document.documentElement.scrollHeight; // Total height of the page

  //     const halfwayPoint = totalHeight / 4;

  //     // Check if the user has scrolled past the halfway point
  //     if (scrollPosition + viewportHeight >= halfwayPoint) {
  //       setIsHalfway(true);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   // Cleanup on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowPopup(true);
  //   }, 3000); // 3000ms = 3 seconds

  //   // Cleanup the timer when the component is unmounted
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <Fragment>
      {location.pathname === "/" ? <Navbar handleSectionChange={handleSectionChange} activeSection={activeSection}/> : <Navbar1 />}
      <Routes>
        {/* <Route path="/" element={<Navigate to="/" />} /> */}
        <Route path="/" element={<Home handleSectionChange={handleSectionChange} activeSection={activeSection} size={size} duration={duration} />} />
        {/* <Route path="/thank-you" element={<Thankyou />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {location.pathname === "/" ? <Footer /> : <Footer1 />}
      {/* {<ZohoForm/> } */}
      {location.pathname === "/" && !closePopup && formAppear &&
      <PopupForm handleClose={handleClose}/>
      }
    </Fragment>
  );
}

export default App;


// useEffect(() => {
//   const consent = localStorage.getItem('userConsent');
//   if (consent === 'accepted') {
//     setHasConsent(true);
//   }
// }, []);

// hasConsent = {hasConsent}
// const [hasConsent, setHasConsent] = useState(false);
// const handleAccept = () => {
//   localStorage.setItem('userConsent', 'accepted');
//   setHasConsent(true);
// };

// const handleDecline = () => {
//   localStorage.setItem('userConsent', 'declined');
  
//   // Try to close the current window
//   if (window.opener) {
//     window.close(); // Will work if the window was opened via a script
//   } else {
//     // Force close by opening and immediately closing a new window
//     window.open('about:blank', '_self').close();
//   }
// };

// {/* If consent hasn't been given, show the consent modal */}
// {!hasConsent && location.pathname === "/videoproduction" && <ConsentModal onAccept={handleAccept} onDecline={handleDecline} />}