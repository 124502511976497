import React, { useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';


import "./Testimonials.css"

// import next_icon from "../../../Assets/Common/next-icon.png"
// import back_icon from "../../../Assets/Common/back-icon.png"
import user1 from "../../../Assets/Testimonials/dahua.webp"
import user2 from "../../../Assets/Testimonials/tenda.webp"
import user3 from "../../../Assets/Testimonials/rpm.webp"
import user4 from "../../../Assets/Testimonials/become.webp"
import user5 from "../../../Assets/Testimonials/dsl.webp"
import Quote from '../../../Assets/Common/blockquote.svg';

const reviews = [
    {
        id: 1,
        image: user1,
        name: 'AMAN YAMIN',
        companyName: 'DAHUA TECHNOLOGIES',
        head: "Outstanding Web Development Team!",
        text: 'Rabbit And Carrot delivered beyond our expectations. Their web development expertise ensured our e-commerce site was optimized for performance and sales. The team’s attention to detail and customer-centric approach was impressive throughout the entire process.'
    },
    {
        id: 2,
        image: user2,
        name: 'AZFER SHEIKH',
        companyName: 'TENDA',
        head: "A Website That Captures Our Brand Perfectly!",
        text: 'Rabbit And Carrot created a stunning website that truly captures our brand’s essence. The design, user interface, and functionality are all top-notch. Their team was incredibly responsive and dedicated to making our vision come to life.'
    },
    {
        id: 3,
        image: user3,
        name: 'MAHESH KUMAR PATARIA',
        companyName: 'RPM GROUP',
        head: "Seamless Experience from Start to Finish!",
        text: 'Rabbit And Carrot transformed our outdated website into a modern, user-friendly platform that truly reflects our brand’s identity. The team handled everything from concept to launch with professionalism and precision. We couldn\'t be happier with the results!'
    },
    {
        id: 4,
        image: user4,
        name: "REHAN NAEEM",
        companyName: "BECOME",
        head: "Exceptional Design and Functionality!",
        text: "Working with Rabbit And Carrot was an absolute game changer for our online presence. Their team designed a sleek, intuitive website that not only looks great but performs flawlessly. Our users love the new experience, and so do we!"
    },
    {
        id: 5,
        image: user5,
        name: "OMAR HAYAT",
        companyName: "DSL",
        head: "Exceptional Digital Media Partnership with Rabbit And Carrots",
        text: "We have recently used Rabbit And Carrot for numerous projects in the UAE including high end stakeholder management. I especially appreciate their willingness to listen to feedback and improve in real time. Thank you."
    }
  ];

const Testimonials = () => {
  return (
    <section id="testimonials" className="testimonial-container">
      <div className="title">
        <h1 className="commonHeading"><span>Work Delights</span> & <span>Inspires Clients</span></h1>
        {/* <h3>Delivering Tailored and Distinctive Website Development Services for Your Business at Affordable Prices.</h3> */}
      </div>
      <div className="slider-container">
        <blockquote>
          <img className="top-quote quote" src={Quote} alt="quote" />
          <img className="bottom-quote quote" src={Quote} alt="quote" />
        </blockquote>

        <Splide
          options={{
            perPage: 1,
            autoplay: true,
            speed: 1000,
            rewind: true,
            rewindByDrag: true,
          }}
        >
          {reviews.map((review) => (
            <SplideSlide key={review.id}>
              <img className="review-img" src={review.image} alt="" />
              <div className="content">
                <p className="head">{review.head}</p>
                <p className="text">{review.text}</p>
                <div className="info">
                  <div className="rating">
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9734;</span>
                  </div>
                  <p className="company">{review.companyName}</p>
                  {/* <p className="user">{review.name}</p> */}
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </section>
  );
};

export default Testimonials;