import { useEffect, useState } from "react"
import { motion } from "framer-motion"

import "./Process.css"
import image from "../../../Assets/Bg/3d.webp"
import arrow from "../../../Assets/Common/arrow.webp"
import arrow2 from "../../../Assets/Common/arrow-flip.webp"
import arrowdown from "../../../Assets/Common/arrow-down.webp"

export default function Process() {
    const [mobileScreen, setMobileScreen] = useState(false)

    useEffect(() => {
        // Function to handle resize and set screen width and size accordingly
        const handleResize = () => {
            const width = window.innerWidth;
            if(width < 768) {
                setMobileScreen(true)
            } else {
                setMobileScreen(false)
            }
        };
        
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        
        // Initial check in case window width has already changed
        handleResize();
        
        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <section id="#WhatWeDo" className="WhatWeDo container1">
            {/* <div className="textContainer">
                <p>Foucs on helping your Brand grow<br/>
                and move Forward</p><hr/>
            </div> */}
            <div className="titleContainer">
                <div className="title">
                    <img src={image} alt="" />
                    <h1>
                        Process
                    </h1>
                </div>
                {/* <div className="title">
                    <h1>
                        <span>For Your</span> Business
                    </h1>
                    <a href="#services"><button>What We Do?</button></a>
                </div> */}
            </div>
            <div className="listContainer">
                <div className="box">
                    <h1>Concept Development & Storyboarding</h1>
                    <ul>
                        <li>Understand the project’s goals, audience, and key message.</li>
                        <li>Write a compelling narrative or script for the animation.</li>
                        <li> Visualize the script through rough sketches to outline scenes, actions, and transitions.</li>
                        <li>Review the storyboard with stakeholders to ensure alignment before proceeding.</li>
                    </ul>
                    {/* <button>Go</button> */}
                </div>
                <motion.img 
                    initial={{
                        rotate: -180,
                        opacity: 0,
                    }}
                    whileInView={{
                        rotate: 0,
                        opacity: 1,
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut" 
                    }}
                    viewport={{ once: false }}
                    src={mobileScreen ? arrowdown : arrow} alt="" className={mobileScreen ? "arrow-down" : "arrow"}/>
                <div className="box">
                    <h1>Design & Asset Creation</h1>
                    <ul>
                        <li>Choose an animation style (2D, 3D, motion graphics) and design visual assets accordingly.</li>
                        <li>Create detailed characters, environments, and elements based on the storyboard.</li>
                        <li>Prepare and organize all design components for seamless animation.</li>
                        {/* <li></li> */}
                    </ul>
                    {/* <button>Go</button> */}
                </div>
                <motion.img 
                    initial={{
                        rotate: 180,
                        opacity: 0,
                    }}
                    whileInView={{
                        rotate: 0,
                        opacity: 1,
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut" 
                    }}
                    viewport={{ once: false }}
                    src={mobileScreen ? arrowdown : arrow2} alt="" className={mobileScreen ? "arrow-down" : "arrow"}/>
                <div className="box">
                    <h1>Animation Production</h1>
                    <ul>
                        <li>Set up major movements and positions to establish the sequence’s flow.</li>
                        <li>Add transitions to create smooth motion between key frames.</li>
                        <li>Refine pacing, add special effects, and ensure fluidity.</li>
                        <li>Sync voiceovers, music, and sound effects with the visuals.</li>
                    </ul>
                    {/* <button>Go</button> */}
                </div>
                {/* <hr/> */}
                <motion.img 
                    initial={{
                        rotate: -180,
                        opacity: 0,
                    }}
                    whileInView={{
                        rotate: 0,
                        opacity: 1,
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut" 
                    }}
                    viewport={{ once: false }}
                    src={mobileScreen ? arrowdown : arrow} alt="" className={mobileScreen ? "arrow-down" : "arrow"}/>
                <div className="box">
                    <h1>Review, Revisions & Delivery</h1>
                    <ul>
                        <li>Check the animation for quality, consistency, and alignment with the initial concept.</li>
                        <li>Share the draft for feedback and make necessary revisions.</li>
                        <li>Export the animation in the required formats with high-quality settings.</li>
                        <li>Deliver the completed animation and any source files to the client.</li>
                    </ul>
                    {/* <button>Go</button> */}
                </div>
            </div>
        </section>
    )
}