import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { AnimatePresence, motion } from 'framer-motion'
import { ImEnlarge } from "react-icons/im";

import FullScreenImage from './FullScreenImage'
import "./Gallary.css"

import play from "../../../Assets/Common/play-button.svg"
import closeButton from "../../../Assets/Common/close-button.svg"

import threeD1 from "../../../Assets/Works/3d/abudhabipolice.webp"
import threeD2 from "../../../Assets/Works/3d/caliber.webp"
import threeD3 from "../../../Assets/Works/3d/mok.webp"
import threeD4 from "../../../Assets/Works/3d/bisconni.webp"
import threeD5 from "../../../Assets/Works/3d/vgotel.webp"
import threeD6 from "../../../Assets/Works/3d/spt.webp"
import threeD7 from "../../../Assets/Works/3d/ecco.webp"
import threeD8 from "../../../Assets/Works/3d/rosepatel.webp"
import threeD9 from "../../../Assets/Works/3d/powerplus.webp"
import threeD10 from "../../../Assets/Works/3d/broccoli.webp"
import threeD11 from "../../../Assets/Works/3d/channel.webp"
import threeD12 from "../../../Assets/Works/3d/rolex.webp"

const videoLinks = {
    video_1: "https://youtu.be/jgcjOxtK5qQ",
    video_2: "https://youtu.be/VH2AH32Mry4",
    video_3: "https://www.youtube.com/watch?v=02TpJ53Jbyg",
    video_4: "https://www.youtube.com/watch?v=AWgFs5XH3fc",
    video_5: "https://youtu.be/ydEDP_SJI9o",
    video_6: "https://youtu.be/CZ4LmhaxWsU",
    video_7: "https://youtu.be/y8I7ZemEMiM",
    video_8: "https://youtu.be/96nsSWCj3J4?si=BXz3_Bz0q4Lkwb2J",
    video_9: "https://youtu.be/jc7d08B4UjQ?si=u_YiNFimdMG23STq",
    video_10: "https://youtu.be/GOCFF0Ipslw?si=-dRO8atOObosDYCa",
    video_11: "https://youtu.be/uRVQA65QY1k",
    video_12: "https://youtu.be/MUECjw6ZeBg",
}

const gallaryVariants = {
  initial: { y: 200, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  exit: { y: -200, opacity: 0, transition: { duration: .5 } },
  // initial1: { x: -100, opacity: 0 },
  // animate1: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  // exit1: { x: -100, opacity: 0, transition: { duration: .5 } },
};

export default function Gallary() {
    const [playingVideo, setPlayingVideo] = useState(null);

    const handleVideoClick = (videoUrl) => {
        setPlayingVideo(videoUrl);
      };

    const [playingImage, setPlayingImage] = useState(null);

    // const handleImageClick = (imageUrl) => {
    //     setPlayingImage(imageUrl);
    //   };

  return (
    <section id="portfolio" className="portfolio">
      <h1 className="commonHeading"><span>Portfolio</span></h1>
        <AnimatePresence mode="wait">
        <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_7)}>
              <img src={threeD7} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">ECO MALL</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_8)}>
              <img src={threeD8} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">ROSE PATEL</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_9)}>
              <img src={threeD9} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">POPWER PLUS</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_10)}>
              <img src={threeD10} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">BROCCOLI</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_11)}>
              <img src={threeD11} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">CHANNEL</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_12)}>
              <img src={threeD12} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">ROLEX</p>
                  </div>
              </div>
          </div>
          </motion.div>
          <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_13)}>
              <img src={threeD1} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">ABU DHABI POLICE</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_14)}>
              <img src={threeD2} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">CALIBER</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_15)}>
              <img src={threeD3} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">MOK</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_16)}>
              <img src={threeD4} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">NOVITA</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_17)}>
              <img src={threeD5} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">VGO TEL</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_18)}>
              <img src={threeD6} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">SPT</p>
                  </div>
              </div>
          </div>
          </motion.div>
        </AnimatePresence>

{/* <       AnimatePresence mode="wait"> */}
          {/* <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants} */}
            <div
              className="button-container content-center">
            <a href="#about-contact"><button className="btn button-touch2"> Explore More
                    <span className="line">
                        <span className="circle"></span>
                    </span>
            </button></a>
            </div>
          {/* </motion.div> */}
          {/* </AnimatePresence> */}
    
    {playingVideo && (
      <div className="video-overlay">
        <div className="video-player">
          <ReactPlayer className="player" url={playingVideo} playing={true} controls width="100%" height="100%" />
        </div>
          <img className="close-button1" onClick={() => setPlayingVideo(null)} src={closeButton}/>
        {/* <img className="close-button1" src={closeButton} alt=""/> */}
      </div>
      )}
    {playingImage && (
      <div className="video-overlay">
        <div className="video-player">
          <FullScreenImage src={playingImage} />
      </div>
        <img className="close-button1" onClick={() => setPlayingImage(null)} src={closeButton}/>
      </div>
    )}
  </section>
  )
}